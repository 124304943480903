<template>
  <v-card class="ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width" color="light_gray" tile elevation="0">
    <v-row align="center" no-gutters>
      <v-col cols="12" md="12" lg="10">
        <v-form ref="profileForm">
          <v-row>
            <v-col cols="6">
              <span class="gray--text font-weight-bold text-body-1 text-capitalize">{{ $t(`label.deliveryAddresses`) }}</span>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="pa-0" @click="openAddAddressDialog">
                <span class="unbox_primary--text text-capitalize font-weight-bold text-body-1 cursor-pointer">{{ $t(`label.addAddress`) }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <template v-for="address in customerInfo.addresses">
              <v-col cols="12" :key="`${address.uuid}_info`" class="my-0 py-0">
                <v-chip v-if="address.is_default_billing" small color="unbox_primary" class="mr-2 white--text mb-2">{{ $t(`label.billingAddress`) }}</v-chip>
                <v-chip v-if="address.is_default_shipping" small color="unbox_primary" class="mr-2 white--text mb-2">{{ $t(`label.shippingAddress`) }}</v-chip>
              </v-col>
              <v-col cols="12" sm="8" :key="address.uuid">
                <table class="profile-address-table">
                  <tr>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="pr-4 gray--text text-body-2">{{ $t(`label.recipientName`) }}</td>
                    <td class="text-body-2">
                      {{ address.display_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-4 gray--text text-body-2">{{ $t(`label.phone`) }}</td>
                    <td class="text-body-2">({{ address.phone_code }}){{ address.phone_no }}</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="pr-4 gray--text text-body-2">{{ $t(`label.address`) }}</td>
                    <td class="text-body-2">{{ address.address_1 }} {{ address.address_2 }} {{ address.address_3 }}</td>
                  </tr>
                  <tr>
                    <td class="text-body-2">{{ address.city }} {{ address.postcode }} {{ address.state }}</td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="12" sm="4" :key="`${address.uuid}_action`" class="text-right">
                <v-btn text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="mr-4 pa-0" @click="editAddress(address)">
                  <span class="unbox_primary--text text-capitalize font-weight-bold text-body-2 cursor-pointer">{{ $t(`label.update`) }}</span>
                </v-btn>
              </v-col>
              <v-col cols="12" :key="`${address.uuid}_divider`">
                <v-divider></v-divider>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { localeHelper } from '@/utils'
import { CUSTOMER_GET_INFORMATION } from '@/store/customer.module'
import { SESSION } from '@/constants'
export default {
  name: 'deliveryAddresses',
  data: () => ({}),
  computed: {
    customerInfo() {
      return this.$store.state.customer.info
    },
    editAddressResponseComplete() {
      return this.$store.state.customer.editAddressResponse.complete
    },
    addAddressResponseComplete() {
      return this.$store.state.customer.addAddressResponse.complete
    }
  },
  created() {},
  watch: {
    editAddressResponseComplete() {
      let response = this.$store.state.customer.editAddressResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.updateAddressComplete`))
          this.$root.$master.deliveryAddressDialogShow = false
          this.getCustomerInformation()
        }
      }
    },
    addAddressResponseComplete() {
      let response = this.$store.state.customer.addAddressResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.addAddressComplete`))
          this.$root.$master.deliveryAddressDialogShow = false
          this.getCustomerInformation()
        }
      }
    }
  },
  methods: {
    openAddAddressDialog() {
      this.$root.$master.deliveryAddressObj.isEdit = false
      this.$root.$master.deliveryAddressDialogShow = true
      this.$root.$master.deliveryAddressObj.isFirstAddress = this.customerInfo.addresses.length <= 0
    },
    editAddress(address) {
      this.$root.$master.deliveryAddressObj.isEdit = true
      this.$root.$master.deliveryAddressObj.address = address
      this.$root.$master.deliveryAddressDialogShow = true
    },
    getCustomerInformation() {
      let data = {
        uuid: localStorage.getItem(SESSION.CUSTOMER_UUID)
      }
      this.$store.dispatch(CUSTOMER_GET_INFORMATION, { data })
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-address-table td {
  vertical-align: top;
}
</style>
